

export default [

    {
        path: "/reports",
        children: [
            {
                path: '/reports',
                name: 'reports',
                component: () => import('@/views/reports/ReportsHome.vue')
            },
            {
                path: '/general-Report',
                name: 'generalReport',
                component: () => import('@/views/reports/generalReport.vue')
            },

            {
                path: '/security-trends',
                name: 'securityTrends',
                component: () => import('@/views/reports/security/trends/securityTrends.vue')
            },
            {
                path: '/security-trend-List',
                name: 'securityTrendList',
                component: () => import('@/views/reports/security/trends/securityTrendList.vue')
            },

            {
                path: '/security-category',
                name: 'securityCategory',
                component: () => import('@/views/reports/security/trends/securityCategory.vue')
            },

            {
                path: '/security-reports',
                name: 'SecurityReports',
                component: () => import('@/views/reports/security/report/securityReports.vue'),
                permission: "['can_view_project_security_assessment']"

            },
            {
                path: '/CyberSecurity-Forensic',
                name: 'CyberSecurityForensic',
                component: () => import('@/views/reports/security/report/CyberSecurityForensic/CyberSecurityForensic.vue')

            },
        
            {
                path: '/block-chain-reports',
                name: 'blockChainReports',
                component: () => import('@/views/reports/blockchain/blockChainProject.vue')

            },
            {
                path: '/hackFu-and-techniques',
                name: 'HackFuAndTechniques',
                component: () => import('@/views/reports/security/report/hackFuAndTechniques/hackFuAndTechniques.vue')
            },

        ]
    },
];
