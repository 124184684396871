// import './assets/main.css';
// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'bootstrap/dist/css/bootstrap.css'



import { createPinia } from 'pinia';
import { createApp, h, provide } from 'vue';
import { apolloClient } from "./apollo.js";
import JsonExcel from "vue-json-excel3";
import VueApexCharts from "vue3-apexcharts";


import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// vue-drop-down
import DropdownMenu from 'v-dropdown-menu';

// Vuetify
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import 'vuetify/styles';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import './assets/main.css';

import App from './App.vue';
import router from './router';
import PermissionPlugin from './plugins/permissionPlugin'
import mixins from "@/services/mixins.js";



const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp({
    setup() {
        provide(apolloClient)
    },

    render: () => h(App),
})

app.use(VueApexCharts);
app.use(createPinia())
app.use(router)
app.use(vuetify)
app.mixin(mixins)
app.use(DropdownMenu)
app.component("downloadExcel", JsonExcel)
    .component('v-select', vSelect)
    .component('QuillEditor', QuillEditor)

app.use(PermissionPlugin)
app.mount('#app')
