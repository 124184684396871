<template>
  <div>
    <main-layout :navigation-links="navigationLinks" />
  </div>
</template>

<script setup>
import MainLayout from "@/components/layout/MainLayout.vue";
import { StorageService } from "@/services/storageService.js";
import { ref } from "vue";

const user = new StorageService().getItem("userProfile");
const isAdmin =
  user &&
  ["INSTITUTION_ADMIN", "SUPER_ADMIN"].includes(user.userProfile.profileType);

const navigationLinks = ref([
  {
    children: [
      {
        name: "Dashboard",
        link: "/dashboard",
        icon: "dashboard",
        permissions: [],
      },
      {
        name: "Projects",
        link: "/projects",
        icon: "project",
        permissions: [],
      },
      { name: "PDP", link: "/pdp", icon: "pdp", permissions: [] },
      {
        name: "Assignment",
        link: "/assignments",
        icon: "assignDesignation",
        permissions: [],
      },
      { name: "Reports", link: "/reports", icon: "report", permissions: [] },
      {
        name: "Settings",
        link: "/settings",
        icon: "setting",
        permissions: ["can_manage_system_settings"],
      },
    ]
        
  },
]);
</script>
