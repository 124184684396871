import { onMounted, onUnmounted } from 'vue';
import router from '@/router';
import { StorageService } from '@/services/storageService.js';

const logout = () => {
  const storageService = new StorageService();
  storageService.clearStorage();
  router.push('/');
};

const useIdleTimeout = () => {
  const IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes
  let idleTimer;

  const resetTimer = () => {
    clearTimeout(idleTimer);

    idleTimer = setTimeout(() => {
      logout();
    }, IDLE_TIMEOUT);
  };

  const trackActivity = () => {
    ['mousemove', 'keydown', 'scroll', 'click'].forEach((event) =>
      window.addEventListener(event, resetTimer)
    );
  };

  const stopTracking = () => {
    ['mousemove', 'keydown', 'scroll', 'click'].forEach((event) =>
      window.removeEventListener(event, resetTimer)
    );
    clearTimeout(idleTimer);
  };

  onMounted(() => {
    trackActivity();
    resetTimer(); // Start the timer on mount
  });

  onUnmounted(() => {
    stopTracking();
  });
};

export default useIdleTimeout;
