<template>
  <div class="top">
    <div class="container-top">
      <div class="row top-div">
        <div class="box-border flex flex-column text-start flex-md-row">
          <header id="home" class="sticky top-0 w-full z-50">
            <nav
              class="flex items-center "
              style="background-color: #1760b2; height: 3.5rem"
            >
              <div class="pl-5 mt-2">
                <img src="@/assets/images/logoLand.svg" class="mt-3" />
              </div>
              <div
                class="flex-1 flex flex-col items-start justify-center px-0 pb-0 box-border max-w-full"
              ></div>
              <div
                class="w-30 flex flex-col items-start justify-center px-4 pb-0 box-border text-center text-base"
              >
                <div
                  class="self-stretch flex flex-row items-center justify-start gap-6 cursor-pointer"
                >
                  <b
                    class="text-white whitespace-nowrap z-2 font-poppins font-bold"
                    >User Manual
                  </b>
                  <login
                    :open-dialog="openDialog"
                    @close-dialog="openDialog = false"
                    class="flex items-center justify-center"
                  ></login>

                  <button
                    type="button"
                    class="primary-btn flex px-3 z-2 mx-1 relative rounded-full"
                    @click="viewDialog"
                  >
                    <span class="font-bold text-mg text-white">Login</span>
                  </button>
                </div>
              </div>
            </nav>
          </header>
        </div>
      </div>
      <div class="h-96">
        <div class="landingImage"></div>
      </div>
      <div class="mt-[-7rem] w-[80%] ml-32">
        <div class="text-center">
          <button class="rounded__btn px-10 py-3 text-base mb-4 font-bold">
            WERIS Features
          </button>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-4"
        >
          <div
            class="card py-3 px-3 intro-x mx-2 gap-3 flex items-center overflow-hidden relative transition-all duration-500 hover:translate-y-2 bg-neutral-50 rounded-lg shadow-xl justify-evenly p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-orange-200"
          >
            <svg
              width="35"
              height="36"
              viewBox="0 0 35 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class=""
            >
              <ellipse
                cx="17.432"
                cy="17.8965"
                rx="17.3363"
                ry="17.5964"
                fill="#1565C0"
              />
              <path
                d="M23.3405 6.90402C23.548 6.92294 23.6387 7.18019 23.4896 7.33026L13.1526 17.8224C13.0358 17.941 12.9522 18.089 12.9103 18.2511L11.6678 23.0811C11.6268 23.2407 11.6277 23.4084 11.6702 23.5676C11.7128 23.7268 11.7957 23.8719 11.9106 23.9885C12.0255 24.1052 12.1685 24.1893 12.3253 24.2325C12.4822 24.2757 12.6474 24.2766 12.8047 24.2349L17.562 22.9739C17.7219 22.9309 17.8676 22.8456 17.9844 22.7267L28.4681 12.0858C28.5009 12.0516 28.5426 12.0277 28.5884 12.0168C28.6342 12.006 28.682 12.0087 28.7264 12.0246C28.7707 12.0405 28.8096 12.069 28.8384 12.1067C28.8673 12.1443 28.8849 12.1896 28.8892 12.2371C29.3254 16.4612 29.3004 20.7213 28.8147 24.9399C28.5376 27.3422 26.6355 29.2275 24.2773 29.4961C19.9582 29.9822 15.5993 29.9822 11.2802 29.4961C8.92082 29.2275 7.01865 27.3422 6.74159 24.9399C6.22937 20.4946 6.22937 16.0039 6.74159 11.5586C7.01865 9.15503 8.92082 7.26973 11.2802 7.00238C15.2865 6.55175 19.3276 6.51879 23.3405 6.90402Z"
                fill="white"
              />
              <path
                d="M25.0126 8.45883C25.0415 8.42947 25.0757 8.40618 25.1135 8.39029C25.1512 8.37439 25.1917 8.36621 25.2325 8.36621C25.2734 8.36621 25.3138 8.37439 25.3516 8.39029C25.3893 8.40618 25.4236 8.42947 25.4524 8.45883L27.2092 10.2432C27.2673 10.3023 27.2999 10.3824 27.2999 10.4658C27.2999 10.5493 27.2673 10.6293 27.2092 10.6884L16.9057 21.149C16.8664 21.1885 16.8176 21.2168 16.7641 21.231L14.3861 21.8615C14.3336 21.8754 14.2786 21.8751 14.2263 21.8607C14.174 21.8463 14.1264 21.8182 14.088 21.7794C14.0497 21.7405 14.0221 21.6921 14.0079 21.6391C13.9937 21.586 13.9935 21.5301 14.0071 21.4769L14.6283 19.0632C14.6422 19.0088 14.67 18.9592 14.7091 18.9194L25.0126 8.45883Z"
                fill="white"
              />
            </svg>

            <h1 class="text-gray-500">
              Creating the project at the first time
            </h1>
          </div>

          <div
            class="card py-3 px-3 intro-x mx-2 gap-3 flex items-center overflow-hidden relative transition-all duration-500 hover:translate-y-2 bg-neutral-50 rounded-lg shadow-xl justify-evenly p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-orange-200"
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19" cy="19" r="19" fill="#1565C0" />
              <path
                d="M28.2466 9.75299L26.3266 11.6729C28.3672 13.7188 29.5131 16.4904 29.5129 19.38C29.5129 25.3985 24.6382 30.2733 18.6196 30.2733C12.601 30.2733 7.72626 25.3985 7.72626 19.38C7.72626 13.8244 11.8793 9.24917 17.2579 8.58196V11.3325C13.3908 11.9861 10.4496 15.3358 10.4496 19.38C10.4496 23.8871 14.1125 27.55 18.6196 27.55C23.1267 27.55 26.7896 23.8871 26.7896 19.38C26.7896 17.1196 25.8773 15.0771 24.3931 13.6065L22.4731 15.5265C23.4535 16.5205 24.0663 17.8821 24.0663 19.38C24.0663 22.3893 21.6289 24.8266 18.6196 24.8266C15.6103 24.8266 13.1729 22.3893 13.1729 19.38C13.1729 16.8473 14.9159 14.7367 17.2579 14.1239V17.0379C16.4409 17.5145 15.8963 18.3723 15.8963 19.38C15.8963 20.8778 17.1218 22.1033 18.6196 22.1033C20.1174 22.1033 21.3429 20.8778 21.3429 19.38C21.3429 18.3723 20.7983 17.5009 19.9813 17.0379V5.76331H18.6196C11.1032 5.76331 5.00293 11.8636 5.00293 19.38C5.00293 26.8964 11.1032 32.9966 18.6196 32.9966C26.136 32.9966 32.2363 26.8964 32.2363 19.38C32.2363 15.6218 30.7112 12.2176 28.2466 9.75299Z"
                fill="white"
              />
            </svg>

            <h1 class="text-gray-500">
              Creating the project at the first time
            </h1>
          </div>

          <div
            class="card py-3 px-3 intro-x mx-2 gap-3 flex items-center overflow-hidden relative transition-all duration-500 hover:translate-y-2 bg-neutral-50 rounded-lg shadow-xl justify-evenly p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-orange-200"
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="19" cy="19" r="19" fill="#1565C0" />
              <path
                d="M12.5 6.5C11.837 6.5 11.2011 6.76339 10.7322 7.23223C10.2634 7.70107 10 8.33696 10 9V29C10 29.663 10.2634 30.2989 10.7322 30.7678C11.2011 31.2366 11.837 31.5 12.5 31.5H27.5C28.163 31.5 28.7989 31.2366 29.2678 30.7678C29.7366 30.2989 30 29.663 30 29V14L22.5 6.5M21.25 8.375L28.125 15.25H21.25M16.1625 19.275H25V28.1125L22.35 25.4625L18.8125 29L15.275 25.4625L18.8125 21.9375"
                fill="white"
              />
            </svg>

            <h1 class="text-gray-500">
              Creating the project at the first time
            </h1>
          </div>
          <div
            class="card py-3 px-3 intro-x mx-2 gap-3 flex items-center overflow-hidden relative transition-all duration-500 hover:translate-y-2 bg-neutral-50 rounded-lg shadow-xl justify-evenly p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-orange-200"
          >
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#1565C0" />
              <path
                d="M26.75 6.75H9.25C7.875 6.75 6.75 7.875 6.75 9.25V26.75C6.75 28.125 7.875 29.25 9.25 29.25H26.75C28.125 29.25 29.25 28.125 29.25 26.75V9.25C29.25 7.875 28.125 6.75 26.75 6.75ZM14.25 24.25H11.75V15.5H14.25V24.25ZM19.25 24.25H16.75V11.75H19.25V24.25ZM24.25 24.25H21.75V19.25H24.25V24.25Z"
                fill="white"
              />
            </svg>

            <h1 class="text-gray-500">
              Creating the project at the first time
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-0 right-0 left-0 fixed footer py-5">
    <footerPage />
  </div>
</template>
<script setup>
import { useWerisStore } from "@/stores";
import { ref } from "vue";
import { useRouter } from "vue-router";
import login from "./LogIn.vue";
import footerPage from "./FooterPage.vue";
const user = ref({
  username: "",
  password: "",
});

const store = useWerisStore();
const router = useRouter();

const openDialog = ref(false);
const viewDialog = () => {
  openDialog.value = true;
};
</script>

<style scoped>
.footer {
  background-color: #1760b2;
}

.card {
  /* width: 300px;
  height: 120px; */
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 20px 30px; */
  /* gap: 13px; */
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
}

#cookieSvg {
  width: 50px;
}

#cookieSvg g path {
  fill: rgb(97, 81, 81);
}

.cookieHeading {
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(26, 26, 26);
}

.cookieDescription {
  text-align: center;
  font-size: 0.7em;
  font-weight: 600;
  color: rgb(99, 99, 99);
}

.cookieDescription a {
  --tw-text-opacity: 1;
  color: rgb(59 130 246);
}

.cookieDescription a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.buttonContainer {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.acceptButton {
  width: 80px;
  height: 30px;
  background-color: #7b57ff;
  transition-duration: 0.2s;
  border: none;
  color: rgb(241, 241, 241);
  cursor: pointer;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #977ef3, 0 2px 4px -1px #977ef3;
  transition: all 0.6s ease;
}

.declineButton {
  width: 80px;
  height: 30px;
  background-color: #dadada;
  transition-duration: 0.2s;
  color: rgb(46, 46, 46);
  border: none;
  cursor: not-allowed;
  font-weight: 600;
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px #bebdbd, 0 2px 4px -1px #bebdbd;
  transition: all 0.6s ease;
}

.declineButton:hover {
  background-color: #ebebeb;
  box-shadow: 0 10px 15px -3px #bebdbd, 0 4px 6px -2px #bebdbd;
  transition-duration: 0.2s;
}

.acceptButton:hover {
  background-color: #9173ff;
  box-shadow: 0 10px 15px -3px #977ef3, 0 4px 6px -2px #977ef3;
  transition-duration: 0.2s;
}

.form-control {
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 25px;
  border-radius: 8px;
}

.title {
  font-size: 28px;
  font-weight: 800;
}

.input-field {
  position: relative;
  width: 100%;
}

.input {
  margin-top: 15px;
  width: 100%;
  outline: none;
  border-radius: 8px;
  height: 45px;
  border: 1.5px solid #ecedec;
  background: transparent;
  padding-left: 10px;
}

.input:focus {
  border: 1.5px solid #2d79f3;
}

.input-field .label {
  position: absolute;
  top: 25px;
  left: 15px;
  color: #ccc;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 2;
}

.input-field .input:focus ~ .label,
.input-field .input:valid ~ .label {
  top: 5px;
  left: 5px;
  font-size: 12px;
  color: #2d79f3;
  background-color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
}

.submit-btn {
  margin-top: 30px;
  height: 55px;
  background: #f2f2f2;
  border-radius: 11px;
  border: 0;
  outline: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  background: linear-gradient(180deg, #0841bd 0%, #0841bd 50%, #0841bd 100%);
  box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #0841bd;
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  cursor: pointer;
}

.submit-btn:hover {
  box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0841bd;
}

.landingImage {
  background-image: url("@/assets/images/Group 1000004451 (1).svg");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position:center */
  height: 100%;
}

footer {
  background-image: url("@/assets/images/Group 1000004428.svg");
}

.rounded__btn {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: #1760b2;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-radius: 3rem /* 24px */;
}

.rounded__btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.btn {
  padding: 1rem 2rem;
  font-weight: 700;
  background: rgb(255, 255, 255);
  color: blueviolet;
  border-radius: 0.5rem;
  border-bottom: 2px solid blueviolet;
  border-right: 2px solid blueviolet;
  border-top: 2px solid white;
  border-left: 2px solid white;
  transition-duration: 1s;
  transition-property: border-top, border-left, border-bottom, border-right,
    box-shadow;
}

.btn:hover {
  border-top: 2px solid blueviolet;
  border-left: 2px solid blueviolet;
  border-bottom: 2px solid rgb(238, 103, 238);
  border-right: 2px solid rgb(238, 103, 238);
  box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px,
    rgba(240, 46, 170, 0.2) 15px 15px;
}
</style>
