import AdminMain from "@/views/AdminMain.vue";
import settingsRouter from "@/views/settings/settingsRouter";
import projectsRouter from "@/views/projects/projectsRouter";
import pdpRouter from "@/views/pdp/pdpRouter";
import reportsRouter from "@/views/reports/reportsRouter";
import assignmentRouter from "@/views/assignment/assignmentRouter.js";
export default [
    {
        path: "/admin",
        component: AdminMain,
        redirect: "/dashboard",
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/views/dashboard/Dashboard.vue')
            },
            ...projectsRouter,
            ...pdpRouter,
            ...reportsRouter,   
            ...settingsRouter,
            ...assignmentRouter,
        ]
    },
];
