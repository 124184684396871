<template>
  <div>
    <div class="flex items-center justify-between">
      <h1 class="text-blue text-2xl font-bold">Notifications</h1>
      <span @click="closeNotification()" class="text-primary cursor-pointer">&times;</span>
    </div>
    <div class="bg-blue-100 w-full text-center my-2 p-2 rounded-lg shadow-sm flex items-center">
      <button @click="setActiveTab('all')" class="w-1/2 px-3 py-1 rounded-lg transition-transform duration-300"
              :class="{'bg-[#1760b2] text-white': activeTab === 'all', 'text-primary': activeTab !== 'all'}">All
      </button>
      <button @click="setActiveTab('unread')" class="w-1/2 px-3 py-1 rounded-lg "
              :class="{'bg-[#1760b2] text-white': activeTab === 'unread', 'text-primary': activeTab !== 'unread'}">Unread
      </button>
    </div>
    <div>
      <div v-if="activeTab === 'all'">
        <div class="py-3 overflow-y-auto">
          <div >
            <div class="" v-for="notification in notifications">
              <div @click="viewNotification(notification)" :class="{'border-gray-100 bg-gray-200': notification?.isRead, 'border-blue-100 bg-blue-100': !notification?.isRead}"  class="mt-2 cursor-pointer relative rounded-lg border-2 w-full  flex items-start pt-3 p-2">
                <div class="mr-2">
                  <img :src="imagePreview(userProfile?.userProfile?.profilePhoto)"
                       class="w-10 h-10 object-cover rounded-full" alt="">
                </div>
                <span class="bg-blue text-white w-3 h-3 absolute top-1 right-1 rounded-full"></span>
                <div class="text-stone-500 text-sm">
<!--                                  {{notification?.isRead}}-->
                  <p class="font-bold"> {{ notification?.sender?.userFirstName }} {{ notification?.sender?.userLastName }}
                  </p>
                  <span class="flex text-sm items-center">
                {{ notification?.message }}
              </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div v-else class="overflow-y-auto">
        <div class="py-3 overflow-y-auto">
          <div class="mt-3">
            <div class="" v-for="notification in notifications">
              <div @click="viewNotification(notification)" v-if="notification.isRead == false"
                   :class="{'border-blue bg-blue-100' : !notification?.isRead}"
                   class="mt-2 cursor-pointer relative rounded-lg border-2 w-full flex items-start pt-3 p-2">
                <div class="mr-2">
                  <img :src="imagePreview(userProfile?.userProfile?.profilePhoto)"
                       class="w-10 h-10 object-cover rounded-full" alt="">
                </div>
                <span class="bg-blue text-white w-3 h-3 absolute top-1 right-1 rounded-full"></span>
                <div class="text-stone-500 text-sm">
                  <!--                {{notification}}-->
                  <p class="font-bold"> {{ notification?.sender?.userFirstName }} {{ notification?.sender?.userLastName }}
                    </p>
                  <span class="flex text-sm items-center">
                {{ notification?.message }}
              </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {storeToRefs} from 'pinia';
import {onMounted, ref, computed} from "vue";
import {useRoute, useRouter} from "vue-router"
import {useWerisStore} from "@/stores/index.js";
import {getNotifications} from "@/graphql/settings/notification.graphql.js";
import {getInitials} from "@/services/mixins.js";
import {getUserProfileAndRole} from "@/graphql/settings/user.graphql.js";
import SvgIcon from "@/components/shared/SvgIcon.vue";

const props = defineProps(['type'])
const emits = defineEmits(['close'])
const width = ref(60)
const store = useWerisStore()
const {getStoreItem} = storeToRefs(store)
const activeTab = ref('all')

const route = useRoute()
const router = useRouter()

const imagePreview = (profilePhoto) => {
  return profilePhoto
      ? `${import.meta.env.VITE_APP_WERIS_FILE_SERVER}${profilePhoto}`
      : new URL("@/assets/images/account.png", import.meta.url).href;
}

const closeNotification = () => {
  emits('close')
}

const setActiveTab = (tab) => {
  activeTab.value = tab
}

const notifications = computed(() => {
  return getStoreItem.value("notifications")
})

const userProfile = computed(() => {
  return getStoreItem.value("userProfile");
});



const viewNotification = (notifyData) => {
  store.loadStoreItem({
    entity: "notifications",
    query: getNotifications,
    filtering: {
      pageNumber: 1,
      uniqueId: notifyData?.uniqueId
    }
  });
  if(notifyData.notificationType === 'PROJECT_SECURITY_ASSESSMENT'){
    router.push(`/project-details/${notifyData?.callbackItemId}`)

  } else if(notifyData.notificationType==='ASSIGNED_ASSIGNMENT'){
  router.push(`/assignment-details/${notifyData?.callbackItemId}`)

  } else if(notifyData.notificationType === 'ASSIGNMENT_ANSWERED'){
    router.push(`/assignments`)

  } else if(notifyData.notificationType === 'ASSIGNED_PROJECT'){
    router.push(`/project-details/${notifyData?.callbackItemId}`)

  }else if(notifyData.notificationType === 'ASSIGNMENT_COMMENT'){
    router.push(`/comments/${notifyData?.callbackItemId}`)

  } else if(notifyData.notificationType === 'PROJECT_COMMENT'){
    router.push(`/view-comment/${notifyData?.callbackItemId}`)

  }
  closeNotification();
}

onMounted(() => {
  store.loadStoreItem({
    entity: "notifications",
    query: getNotifications,
    filtering: {
      pageNumber: 1,
    }
  });
  store.loadStoreItem({
    entity: "userProfile",
    query: getUserProfileAndRole,
  });
})
</script>
<style scoped>
</style>
