<template>
  <section
    :class="[
      !isVisible
        ? 'side bg-[#1565C0] flex-col shadow-md pl-3 text-white'
        : 'lg:flex side  flex-col shadow-md pl-3 text-white',
      'w-24 min-w-24 lg:w-60 lg:min-w-60',
    ]"
  >
    <div class="mt-28">
      <template
        class="p-2"
        v-for="(link, index) in navigationLinks"
        :key="index"
      >
        <div v-permission="childLink?.permissions || []"
        @click="navigateTo(childLink?.link)"
          v-for="childLink in link?.children"
          class="flex mt-2 ml-4 hover:bg-black hover:bg-opacity-30 hover:z-30 relative p-2 rounded-l-lg items-center cursor-pointer"
          :class="{ active: isActive(childLink?.link) }"
        >
          <!-- <router-link router-link :to="childLink?.link" class="flex"> -->
            <svg-icon
              v-if="childLink?.icon"
              :name="childLink?.icon"
              color="white"
              class="h-6 w-6 mr-1"
            ></svg-icon>
            <div class="hidden lg:block text-md">
              {{ childLink?.name }}
            </div>
          <!-- </router-link> -->

          <div
            :class="
              isActive(childLink?.link)
                ? 'rounded-br-full z-20 bg-[#1565C0] h-8 w-[2.3rem] -top-8 -right-1 absolute'
                : ''
            "
          ></div>
          <div
            :class="
              isActive(childLink?.link)
                ? 'rounded-br-full z-10 bg-white h-8 w-8 -top-4 -right-1 absolute'
                : ''
            "
          ></div>
          <div
            :class="
              isActive(childLink?.link)
                ? 'rounded-tr-full z-20 bg-[#1565C0] h-8 w-[2.3rem] -bottom-8 -right-1 absolute'
                : ''
            "
          ></div>
          <div
            :class="
              isActive(childLink?.link)
                ? 'rounded-tr-full z-10 bg-white h-8 w-8 -bottom-4 -right-1 absolute'
                : ''
            "
          ></div>
        </div>
      </template>
    </div>

    <!-- <div class="air air1"></div>
    <div class="air air2"></div>
    <div class="air air3"></div>
    <div class="air air4"></div> -->
  </section>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import SvgIcon from "../shared/SvgIcon.vue";
import router from "@/router";

const props = defineProps(["navigationLinks", "panelName", "isVisible"]);
const route = useRoute();
const hoveredLink = ref(null);
const childHoveredLink = ref(null);

const isActive = (link) => {
  return route.fullPath.includes(link);
};

const navigateTo = (link) => {
  if (link) {
    router.push(link); 
  }
}
</script>

<style scoped lang="scss">
.active {
  background-color: white;
  color: black;
}

.inactive {
  color: #707070;
}

.inactive:hover {
  color: #257ae4;
}

.side {
  position: relative;
  overflow-x: hidden;
}

.side .air {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
  background-size: 1000px 100px;
}

.side .air.air1 {
  animation: wave 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}

.side .air.air2 {
  animation: wave2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

.side .air.air3 {
  animation: wave 30s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}

.side .air.air4 {
  animation: wave2 5s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}

@keyframes wave {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: 1000px;
  }
}

@keyframes wave2 {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: -1000px;
  }
}
.werisImage {
  background-image: url("@/assets/images/werisWaves.png");
  height: 100rem;
}
</style>
