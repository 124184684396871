<template>
    <div class="text-center text-white z-10">
         &copy; Copyright
         <span class="text-white font-semibold">WERIS Version 02. all right
         resolve.</span> <br>
         System Designed and Developed by<span class="text-white ml-1 font-bold"><a href="http://www.ega.go.tz">eGA</a></span>
       </div>
 </template>
 <script setup>
 
 
 </script>
 <style scoped>
 .container__main {
   width: 100%;
 }
 
 @media (min-width: 640px) {
   .container__main {
     max-width: 640px;
   }
 }
 
 @media (min-width: 768px) {
   .container__main {
     max-width: 768px;
   }
 }
 
 @media (min-width: 1024px) {
   .container__main {
     max-width: 1024px;
   }
 }
 
 @media (min-width: 1280px) {
   .container__main {
     max-width: 1280px;
   }
 }
 
 @media (min-width: 1536px) {
   .container__main {
     max-width: 1536px;
   }
 }
 
 .container__main {
   margin-left: auto;
   margin-right: auto;
   padding-left: 0.75rem;
   padding-right: 0.75rem;
 }
 </style>