import { getUserProfileAndRole } from "@/graphql/settings/user.graphql";
import { useWerisStore } from "@/stores/index.js";
import { storeToRefs } from "pinia";
import { watch } from "vue";
const PermissionSymbol = Symbol("PermissionPlugin");
export function usePermission() {
  const checkPermission = (requiredPermissions, userProfile) => {
    if (!requiredPermissions || requiredPermissions.length === 0) {
      return true;
    }
    if (!userProfile) {
      return false;
    }
    const permissionsToCheck = Array.isArray(requiredPermissions)
      ? requiredPermissions
      : [requiredPermissions];
    const userRoles = userProfile.userRoles || [];
    return permissionsToCheck.every((permission) =>
      userRoles.some((role) =>
        role.rolePermissions?.some((p) => p.permissionCode === permission)
      )
    );
  };
  return {
    checkPermission,
  };
}
export default {
  install(app, options = {}) {
    const store = useWerisStore();
    const { getStoreItem } = storeToRefs(store);
    const defaultOptions = {
      getUserProfile: () => {
        const profileProxy = getStoreItem.value("myProfile");
        return profileProxy ? JSON.parse(JSON.stringify(profileProxy)) : null;
      },
    };
    const pluginOptions = { ...defaultOptions, ...options };
    app.directive("permission", {
      async mounted(el, binding) {
        el.style.visibility = "hidden";
        await store.loadStoreItem({
          entity: "myProfile",
          query: getUserProfileAndRole,
        });
        let unwatch = null;

        const checkAndApplyPermissions = (profile) => {
          if (profile && Object.keys(profile).length > 0) {
            const { checkPermission } = usePermission();
            if (!checkPermission(binding.value, profile)) {
              el.style.display = "none";
              el.remove();
            } else {
              el.style.visibility = "visible";
            }
            if (unwatch) {
              unwatch();
            }
          }
        };
        const currentProfile = getStoreItem.value("myProfile");
        if (currentProfile && Object.keys(currentProfile).length > 0) {
          checkAndApplyPermissions(currentProfile);
        } else {
          unwatch = watch(
            () => getStoreItem.value("myProfile"),
            (newProfile) => {
              if (newProfile && Object.keys(newProfile).length > 0) {
                checkAndApplyPermissions(newProfile);
              }
            },
            { immediate: true }
          );
        }
      },
      unmounted(el) {},
    });
    app.config.globalProperties.$hasPermission = (requiredPermissions) => {
      const userProfile = pluginOptions.getUserProfile();
      const { checkPermission } = usePermission();
      return checkPermission(requiredPermissions, userProfile);
    };
    app.provide(PermissionSymbol, {
      hasPermission: (requiredPermissions) => {
        const userProfile = pluginOptions.getUserProfile();
        const { checkPermission } = usePermission();
        return checkPermission(requiredPermissions, userProfile);
      },
    });
  },
};
