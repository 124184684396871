<template>
 <!-- <div class="flex flex-col bg-red-900 p-0" style="height: 100vh; font-weight: 400;">
    <div class="flex overflow-hidden" style="height: 100vh;">
      <side-bar :navigation-links="navigationLinks" :panel-name="panelName"></side-bar>
      <div class="py-3 px-4 w-full rounded-r-xl shadow-lg overflow-y-scroll"
        style="background-color: #E8E8E8; height: 100%;">
        <app-bar></app-bar>
          <router-view></router-view>
        
      </div>
    </div>
  </div> -->
  <div class="bg-gray-100" style="height: 100vh">
    <app-bar :navigation-links="navigationLinks"></app-bar>
    <div class="flex overflow-hidden" style="height: 100vh">
      <side-bar
        class="hidden md:block"
        :navigation-links="navigationLinks"
      ></side-bar>
      <div class="flex flex-col w-full">
        <div
          class="grow shadow-md bg-[#f2f8f9] mt-20 rounded-md overflow-y-scroll overflow-x-hidden"
        >
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, watch } from "vue";
import AppBar from "./AppBar.vue";
import SideBar from "./SideBar.vue";
import MobileNav from "./MobileNav.vue";
const messageFromParent = ref("Hello from parent");
const isVisible = ref(false);
const props = defineProps(["navigationLinks", "panelName", "isVisible"]);

const updateParent = (message) => {
  isVisible.value = message;
};
</script>

<style scoped></style>
