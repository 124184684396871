import gql from 'graphql-tag';
import { baseResponseGraphql } from "./base.graphql.js";


// export const activateMemberAccountMutation = gql`
// mutation ActivateMemberAccountMutation($input: ActivateInputObject!) {
//   activateMemberAccountMutation(input: $input) {
//     ${baseResponseGraphql}
//   }
// }
// `

// export const updateMyProfileMutation = gql`
// mutation UpdateMyProfileMutation($input: UserInputObject) {
//     updateMyProfileMutation(input: $input) {
//         ${baseResponseGraphql}
//         data {
//             ${userProfileFields}
//         }
//     }
// }
// `

export const resetPasswordMutation = gql`
mutation ResetPasswordMutation($input: SetPasswordInputObject!){
  resetPasswordMutation(input: $input){
    ${baseResponseGraphql}
  }
}
`

export const changePasswordMutation = gql`
mutation ChangeUserPasswordMutation($input: ChangePasswordInputObject!){
  changeUserPasswordMutation(input: $input){
    ${baseResponseGraphql}
  }
}
`

export const forgotPasswordMutation = gql`
mutation ForgotPasswordMutation($userEmail: String!){
  forgotPasswordMutation(userEmail: $userEmail){
    ${baseResponseGraphql}
  }
}
`