

export const baseResponseGraphql = `
response {
    id
    status
    code
    message
}
`
export const basePaginationGraphql = `
page {
  number
  hasNextPage
  hasPreviousPage
  nextPageNumber
  previousPageNumber
  numberOfPages
  totalItems
  pages
}
`

// "uniqueId": "94e1a5ab-0de9-4d01-9845-3d8a3d66290a"
