
import { StorageService } from "@/services/storageService.js";
export default [
    {
        path: "/pdp",     
        beforeEnter: (to, from, next) => {
            const user = new StorageService().getItem('userProfile');
            if (user && (user.userProfile.profileType === 'INSTITUTION_ADMIN' || user.userProfile.profileType === 'SUPER_ADMIN')) {
                if (to.path !== '/pdp/managerpdp') {
                    // Only redirect if not already on /pdp/managerpdp
                    next('/pdp/managerpdp');
                } else {
                    next(); // Already on the correct path, just proceed
                }
            } else if (user && user.userProfile.profileType === 'INSTITUTION_USER') {
                if (to.path !== '/pdp') {
                    // Only proceed if not already on /pdp
                    next('/pdp');
                } else {
                    next(); // Already on the correct path, just proceed
                }
            } else {
                // Handle unauthorized access or redirect to a login page, etc.
                next('/login'); // Example: redirect to login if the user is not authenticated
            }
        },
        
        children: [
            {
                path: '/pdp',
                name: 'pdp',
                component: () => import('@/views/pdp/pdpList.vue')
            },
            {
                path: '/pdp/:userId',
                name: 'userPdp',
                component: () => import('@/views/pdp/pdpList.vue')
            },
            {
                path: 'managerpdp',
                name: 'managerPdp',
                component: () => import('@/views/pdp/managerPdpList.vue'),
            },
            {
                path: 'details/:pdpId',
                name: 'pdpDetails',
                component: () => import('@/views/pdp/pdpDetails/pdpDetails.vue'),
                children: [
                    {
                        path: 'tech-details',
                        name: 'techDetails',
                        component: () => import('@/views/pdp/pdpDetails/techDetails.vue')
                    },
                    {
                        path: 'material-details',
                        name: 'materialDetails',
                        component: () => import('@/views/pdp/pdpDetails/materialDetails.vue')
                    },
                    {
                        path: 'attachment-details',
                        name: 'attachmentDetails',
                        component: () => import('@/views/pdp/pdpDetails/attachmentDetails.vue')
                    },
                    {
                        path: 'remarks',
                        name: 'remarks',
                        component: () => import('@/views/pdp/pdpDetails/RemarksPage.vue')
                    },
                    {
                        path: 'challenges',
                        name: 'challenges',
                        component: () => import('@/views/pdp/pdpDetails/ChallengesPage.vue')
                    }
                ]
            }
        ]
    }

];
