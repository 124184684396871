// import AdminMain from "@/views/AdminMain.vue";

export default [
    {
        path: "/assignments",
        // component: AdminMain,
        // redirect: "/dashboard",
        children: [
            {
                path: '/assignments',
                name: 'assignments',
                component: () => import('@/views/assignment/assignmentList.vue')
            },
            {
                path: '/my-assignments',
                name: 'my-assignments',
                component: () => import('@/views/assignment/adminAssignmentList.vue')
            },
            {
                path: '/special-tasks',
                name: 'special-tasks',
                component: () => import('@/views/assignment/specialTask/specialTaskList.vue')
            },
            {
                path: '/my-special-tasks',
                name: 'my-special-tasks',
                component: () => import('@/views/assignment/specialTask/adminSpecialTaskList.vue')
            },
            {
                path: '/special-tasks-details/:specialTaskId',
                name: 'special-tasks-details',
                component: () => import('@/views/assignment/specialTask/specialTaskDetails.vue')
            },
            {
                path: '/assignment-details/:assignmentId',
                name: 'assignment-details',
                component: () => import('@/views/assignment/assignmentDetails.vue')
            },
            {
                path: '/:assignmentId/assignment-report',
                name: 'assignment-report',
                component: () => import('@/views/assignment/reportingAssignment.vue')
            },
            {
                path: '/comments/:assignmentId',
                name: 'assignment-comment',
                component: () => import('@/views/assignment/viewComment.vue')
            },
            {
                path: '/report-details/:reportId',
                name: 'report-details',
                component: () => import('@/views/assignment/reportActivities.vue')
            },
            {
                path: '/special-task-report-details/:specialTaskId/:specialTaskReportId',
                name: 'special-task-report-details',
                component: () => import('@/views/assignment/specialTask/reportSpecialTaskActivities.vue')
            },

        ]
    },
];

