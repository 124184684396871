import router from "@/router/index.js";
import {StorageService} from "@/services/storageService.js";
import * as _ from 'lodash';


export function canAccess(codes) {

    let storageService = new StorageService()
    let d = storageService.getItem('userProfile')

    let permissions = []

    for (const userRole of d?.userRoles) {
        let rolePermissions = userRole?.rolePermissions?.map(permission => permission.permissionCode)
        for (const rolePermission of rolePermissions) {
            if (!permissions?.includes(rolePermission)) {
                permissions.push(rolePermission)
            }
        }
    }

    if (_.isArray(codes)) {
        for (const code of codes) {
            if (permissions?.includes(code)) return true
        }
    } else {
        return permissions?.includes(codes)
    }
}

export default {
    data() {
        return {
            SYSTEM_NAME: 'Weris'
        }
    },
    methods: {
        goTo(link) {
            router.push(link)
        },
        goBack() {
            router.go(-1)
        },
        hasAccess: () => (codes) => canAccess(codes),
    }
}

export const getInitials = (firstName, lastName) => {
    // Get the first letter of firstName and lastName if they exist
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';

    // Return the initials
    return `${firstInitial}${lastInitial}`;
}
