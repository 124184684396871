import gql from "graphql-tag";
import {designationFields, institutionFields} from "@/graphql/settings/user.graphql.js";
import {basePaginationGraphql, baseResponseGraphql} from "@/graphql/base.graphql.js";

export const userProfileFields = `
         id
        profileUniqueId
        userFirstName
        userLastName
        userEmail
        profilePhone
        profilePhoto
        profileTitle
        profileType
        profileGender
        numberOfProjects
        profileUserInstitution{
        ${institutionFields}
        }
        userDesignation{
        ${designationFields}
        }
        profileSkills{
        id
        uniqueId
        skillLevel
        skill{
        id
        uniqueId
        name
        }
        }
`
export const getNotifications = gql `
query getNotifications($filtering: NotificationFilteringInputObject){
  getNotifications(filtering:$filtering){
  ${baseResponseGraphql}
  ${basePaginationGraphql}
    data{
      id
      uniqueId
      message
      sender{
      ${userProfileFields}
      }
      received
      receiver{
      ${userProfileFields}
      }
      readOn
      isRead
      callbackItemId
      notificationType
      createdAt
    }
  }
}
`