import Landing from '@/views/LandingPage.vue'
import mainRouter from '@/views/mainRouter'
// import templateRouter from '@/views/template-management/templateRouter'
// import projectsRouter from '@/views/projects/projectsRouter'
import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '../views/LogIn.vue'
import FrontPage from '@/views/FrontPage.vue'
import authService from '@/services/authService.js';
import { StorageService } from "../services/storageService.js";

const router = createRouter({
  history: createWebHistory('/'),
  routes: [
    {
      path: '/',
      name: 'land',
      component: FrontPage
    },
    {
      path: '/activate/:token',
      name: 'activateuser',
      component: () => import('@/views/authentication/ActivateUser.vue')
    },
    {
      path: '/change_password',
      name: 'changepassword',
      component: () => import('@/views/authentication/ChangePassword.vue')
    },
    {
      path: '/forgot_password',
      name: 'forgotpassword',
      component: () => import('@/views/authentication/ForgotPassword.vue')
    },
    {
      path: '/auth/ubunifu-sso/callback',  // This is your redirect URI
      name: 'Callback',
      component: FrontPage,
      beforeEnter: async (to, from, next) => {
        try {
          await authService.handleCallback();
          const storageService = new StorageService();
          const userProfile = storageService.getItem('userProfile');
          if (userProfile) {
            next('/dashboard');
          } else {
            next('/');
          }
        } catch (error) {
          console.error('Callback error:', error);
          next('/');
        }
      }
    },
    ...mainRouter,
    // ...projectsRouter,
  ]
})
// Define the global guard for protected routes
router.beforeEach((to, from, next) => {
  const storageService = new StorageService();
  const token = storageService.getItem('access_token');

  // Define the base paths that require authentication
  const protectedBasePaths = ['/dashboard', '/admin', '/projects', '/pdp', '/reports', '/assignments', '/settings', '/skills'];

  // Check if any protected base path matches the start of the current path
  const requiresAuth = protectedBasePaths.some((path) => to.path.startsWith(path));

  if (requiresAuth && !token) {
    // Redirect to login if not authenticated
    next('/');
  } else {
    // Continue to the next route
    next();
  }
});

export default router
