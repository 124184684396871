import { gql } from 'graphql-tag';
import { basePaginationGraphql, baseResponseGraphql } from '../base.graphql';
import { departmentFields } from "@/graphql/settings/designations.graphql.js";

export const userPermissionFields = `
id
permissionUniqueId
permissionName
permissionCode
`

export const userSkillsFields = `
id
uniqueId
skillLevel
skill{
id
uniqueId
name
category{
id
uniqueId
name
}
}
`

export const userRoleFields = `
id
roleUniqueId
roleName
roleDescription
rolePermissions {
    ${userPermissionFields}
}
`
export const institutionFields = `
        id
        institutionUniqueId
        institutionName
        institutionDescription
        institutionLogo
        institutionDepartments{
          ${departmentFields}
        }
`

export const designationFields = `
        id
        designationUniqueId
        designationShortForm
        designationDescriptions
`

export const userProfileFields = `
         id
        profileUniqueId
        userFirstName
        userLastName
        userEmail
        profilePhone
        profilePhoto
        profileTitle
        profileType
        profileGender
        numberOfProjects
        profileUserInstitution{
        ${institutionFields}
        }
        userDesignation{
        ${designationFields}
        }
        profileSkills{
        id
        uniqueId
        skillLevel
        skill{
        id
        uniqueId
        name
        }
        }
        profileAreaOfExpertise{
          id
          uniqueId
          expertiseAbbreviation
          expertiseName
        }
`

export const userDesignationFields = `
id
designationUniqueId
designationShortForm
designationDescriptions
`

export const handoverRoles = `
        id
        roleUniqueId
        roleName
        roleDescription
        rolePermissions{
         ${userPermissionFields}
        }
`

export const getUsers = gql`
query GetUsers($filtering: UserFilteringInputObject) {
  getUsers(filtering: $filtering) {
    response {
      id
      status
      code
      message
    }
    page {
      number
      hasNextPage
      hasPreviousPage
      nextPageNumber
      previousPageNumber
      numberOfPages
      totalItems
      pages
    }
    data {
      id
      profileUniqueId
      userFirstName
      userLastName
      userEmail
      profilePhone
      profileTitle
      profilePhoto
      profileType
      profileGender
      numberOfProjects
      profileUserInstitution {
        id
        institutionUniqueId
        institutionName
        institutionDescription
        institutionLogo
        institutionDepartments {
          id
          departmentUnitUniqueId
          departmentUnitName
          departmentUnitResponsibility
          departmentUnitType
          departmentUnitSections {
            id
            sectionUniqueId
            sectionName
            sectionDescription
          }
        }
      }
      userDesignation {
        id
        designationUniqueId
        designationShortForm
        designationDescriptions
      }
      profileSkills {
        id
        uniqueId
        skillLevel
        skill {
          id
          uniqueId
          name
          category {
            id
            uniqueId
            name
          }
        }
      }
      profileAreaOfExpertise {
        id
        uniqueId
        expertiseAbbreviation
        expertiseName
      }
    }
  }
}
`

export const getUserProfileAndRole = gql`
    query GetUserProfileAndRole{
    getUserProfileAndRole{
        ${baseResponseGraphql}
        data{
            id
            userProfile{
                ${userProfileFields}
            }
            userInstitution{
                ${institutionFields}
            }
            userRoles{
                ${userRoleFields}
            }
            userDesignation{
            ${userDesignationFields}
            }
            handoverRoles{
                ${handoverRoles}
            }
        }
    }
}
`

export const getCurrentUserRoles = gql`
query{
    getCurrentUserRoles{
        ${baseResponseGraphql}
        data{
        ${userRoleFields}
        }
    }
}
`

export const getSystemPermissions = gql`
query{
        getSystemPermissions{
            ${baseResponseGraphql}
            data{
                id
                permissionGroupUniqueId
                permissionGroupName
                permissionGroupIsGlobal
                permissions{
                ${userPermissionFields}
                }
            }
        }
    }
`

export const activateUserMutation = gql`
mutation ActivateUserMutation($input: ActivateUserInputObject!) {
    activateUserMutation(input: $input) {
        ${baseResponseGraphql}
    }
}
`
export const createUserMutation = gql`
mutation CreateUserMutation($input: UserInputObject!){
    createUserMutation(input: $input) {
        ${baseResponseGraphql}
        data{
         ${userProfileFields}
        }
    }
}
`


export const updateUserMutation = gql`
mutation UpdateUserMutation($input: UserInputObject!){
  updateUserMutation(input: $input){
    ${baseResponseGraphql}
    data{
     ${userProfileFields}
    }
  }
}
`
export const deleteUserMutation = gql`
mutation DeleteUserMutation($profileUniqueId: String!){
  deleteUserMutation(profileUniqueId: $profileUniqueId){
    ${baseResponseGraphql}
  }
}
`

export const updateUserProfileMutation = gql`
mutation updateUserProfileMutation($input: MyProfileInputObject){
    updateUserProfileMutation(input: $input){
      ${baseResponseGraphql}
      data{
        ${userProfileFields}
      }
    }
  }
`
export const updateMyProfilePhotoMutation = gql`
mutation UpdateMyProfilePhotoMutation($profilePhotoPath: String!){
    updateMyProfilePhotoMutation(profilePhotoPath: $profilePhotoPath){
      ${baseResponseGraphql}
      data{
        ${userProfileFields}
      }
    }
  }
`

export const deleteSectionUserMutation = gql`
mutation DeleteSectionUsersMutation($input: SectionUsersInputObject!){
  deleteUserFromSectionMutation(input:$input){
    ${baseResponseGraphql}
  }
}
`
export const createPersonalSkillMutation
  = gql`
mutation CreatePersonalSkillMutation
($input: PersonalSkillInputObject!){
    createPersonalSkillMutation
(input: $input) {
        ${baseResponseGraphql}
        data{
         ${userSkillsFields}
        }
    }
}
`

export const deletePersonalSkillMutation = gql`
mutation DeletePersonalSkillMutation($personalSkillUniqueId: String!){
  deletePersonalSkillMutation(personalSkillUniqueId: $personalSkillUniqueId){
    ${baseResponseGraphql}
  }
}
`