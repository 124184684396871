import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { setContext } from "@apollo/client/link/context";
import { StorageService } from "./services/storageService.js";

const httpLink = createHttpLink({
    uri: `${import.meta.env.VITE_APP_WERIS_SERVER}graphql`,
})

const cache = new InMemoryCache({ addTypename: false })

let storageService = new StorageService()


const authLink = setContext((_, { headers }) => {
    const authToken = storageService.getItem('access_token');
    return {
        headers: {
            ...headers,
            authorization: authToken ? `Bearer ${authToken}` : '', // Add your authorization header here
        },
    };
});


export const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
})
