// import AdminMain from "@/views/AdminMain.vue";

export default [
    {
        path: "/projects",
        children: [
            {
                path: '/projects',
                name: 'projects',
                component: () => import('@/views/projects/projectList.vue')
            },
            {
                path: '/project-details/:id',
                name: 'project-details',
                component: () => import('@/views/projects/projectDetails.vue')
            },
            {
                path: '/report-project/:id',
                name: 'report-project',
                component: () => import('@/views/projects/reportProject.vue')
            },
            {
                path: '/view-comment/:id',
                name: 'view-comment',
                component: () => import('@/views/projects/viewComment.vue')
             } ,

        ]
    },
];
