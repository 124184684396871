import { gql } from 'graphql-tag'
import { basePaginationGraphql, baseResponseGraphql } from '../base.graphql'

export const designationFields = `
        id
        designationUniqueId
        designationShortForm
        designationDescriptions
`

export const sectionFields = `
id
sectionUniqueId
sectionName
sectionDescription
`

export const departmentFields = `
          id
          departmentUnitUniqueId
          departmentUnitName
          departmentUnitResponsibility
          departmentUnitType
          departmentUnitSections{
            ${sectionFields}
          }
`

export const institutionFields = `
        id
        institutionUniqueId
        institutionName
        institutionDescription
        institutionLogo
        institutionDepartments{
          ${departmentFields}
        }
`

export const userProfileFields = `
        id
        profileUniqueId
        userFirstName
        userLastName
        userEmail
        profilePhone
        profilePhoto
        profileTitle
        profileType
        profileGender
        numberOfProjects
        profileUserInstitution{
        ${institutionFields}
        }
        userDesignation{
        ${designationFields}
        }
        profileSkills{
        id
        uniqueId
        skillLevel
        skill{
        id
        uniqueId
        name
        }
        }
`

export const createDesignationMutation = gql`
mutation CreateDesignationMutation($input:DesignationInputObjects!){
    createDesignationMutation(input: $input){
        ${baseResponseGraphql}
        data{
            ${designationFields}
        }
    }
}
`

export const updateDesignationMutation = gql`
mutation UpdateDesignationMutation($input:DesignationInputObjects!){
    updateDesignationMutation(input: $input){
        ${baseResponseGraphql}
        data{
            ${designationFields}
        }
    }
}
`

export const getDesignations = gql`
query GetDesignations($filtering: DesignationFilteringInputObject){
    getDesignations(filtering: $filtering){
        ${baseResponseGraphql}
        ${basePaginationGraphql}
        data{
            ${designationFields}
        }
    }
}
`

export const deleteDesignationMutation = gql`
mutation DeleteDesignationMutation($designationUniqueId: String!){
    deleteDesignationMutation(designationUniqueId: $designationUniqueId){
        ${baseResponseGraphql}
    }
}
`
export const assignUserToDesignationMutation = gql`
mutation AssignUserToDesignationMutation($input:DesignationUsersInputObject!) {
    assignUserToDesignationMutation(input: $input){
      ${baseResponseGraphql}
      data{
        id
        uniqueId
        designation{
          ${designationFields}
        }
        userProfile{
            ${userProfileFields}
        }
      }
    }
  }
`