import { gql } from 'graphql-tag'
import { basePaginationGraphql, baseResponseGraphql } from '../base.graphql';
// import { userProfileFields } from '../settings/user.graphql';

export const pdpData = `
  id
  pdpUniqueId
  pdpOwner {
    id
    profileUniqueId
    userFirstName
    userLastName
    userEmail
    profilePhone
    profileTitle
    profilePhoto
    profileType
    profileGender
    userDesignation {
      id
      designationUniqueId
      designationShortForm
      designationDescriptions
    }
  }
  pdpSkill {
    id
    uniqueId
    name
    category {
      id
      uniqueId
      name
    }
  }
  pdpLearningGoal
  pdpEndDate
  pdpStartDate
  pdpAward
  pdpCostToBeIncurred
  pdpStatus
`
export const pdpSummaryData = `
uniqueId
project
weekSummary
`
export const pdpApplicationData = `
id
pdpAreaOfApplicationUniqueId
pdpAreaOfApplicationType
pdpAreaOfApplicationProject{
id
uniqueId
projectName
projectDescription
projectStartDate
projectEndDate
projectStatus
projectCategories{
id
uniqueId
categoryName
}
}             
pdpAreaOfApplicationArea
`

export const pdpChallengeData = `
id
pdpChallengeUniqueId
pdpChallangeType
pdpChallangeName
`
export const pdpRemarkData = `
id
pdpRemarkUniqueId
pdpRemarker{
id
profileUniqueId
userFirstName
userLastName
userEmail
profilePhone
profileTitle
profilePhoto
profileType
profileGender
profileUserInstitution{
institutionName
}
userDesignation{
id
designationUniqueId
designationShortForm
designationDescriptions
}
profileSkills{
skillLevel
}
}
pdpRemark
createdDate
pdpRemarkResponses{
id
pdpRemarkResponseUniqueId
pdpRemarkResponse
}
`
export const pdpRemarkResponseData = `
id
pdpRemarkResponseUniqueId
pdpRemarkResponse
`
export const usefulLinksData = `
id
pdpUsefulLinkName
pdpUsefulLinkPath
pdpUsefulLinkUniqueId
`
export const pdpAttachmentData = `
id
pdpAttachmentUniqueId
pdpAttachmentName
pdpAttachmentType
pdpAttachmentPath
`
export const SkillsData = `
  id
  name
  uniqueId
  category{
    id
    name
    uniqueId
  }

`
export const skillsCategoryData = `
  id
  name
  uniqueId
`

export const pdpProjectActivityCommentData = `
id
uniqueId
projectActivityComment
`


export const getPdp = gql`
query Getpdp($filtering:DevelopmentPlanFilteringInputObject){
  getPdp(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${pdpData}
    }
    }
  }
`
export const createPdpMutation = gql`
mutation createPdpMutation($input: DevelopmentPlanInputObject!){
  createPdpMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpData}
    }
  }
}
`
export const updatePdpMutation = gql`
mutation updatePdpMutation($input: DevelopmentPlanInputObject!){
  updatePdpMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpData}
    }
  }
}`
export const deletePdpMutation = gql`
mutation deletePdpMutation($pdpUniqueId: String!){
  deletePdpMutation(pdpUniqueId: $pdpUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const getPdpUsefulLink = gql`
query getPdpUsefulLink
($filtering:PdpUsefulLinkFilteringInputObject){
  getPdpUsefulLink
(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${usefulLinksData}
    }
    }
  }
`
export const createPdpUsefullinkMutation = gql`
mutation createPdpUsefullinkMutation($input: PdpUsefulLinkInputObject!){
  createPdpUsefullinkMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${usefulLinksData}
    }
  }
}
`
export const deletePdpUsefullinkMutation = gql`
mutation deletePdpUsefullinkMutation($pdpUsefulLinkUniqueId: String!){
  deletePdpUsefullinkMutation(pdpUsefulLinkUniqueId: $pdpUsefulLinkUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const getPdpAttachment = gql`query getPdpAttachment
($filtering:PdpAttachmentFilteringInputObject){
  getPdpAttachment(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${pdpAttachmentData}
    }
    }
  }
`
export const createPdpAttachmentMutation = gql`
mutation createPdpAttachmentMutation($input: PdpAttachmentInputObject!){
  createPdpAttachmentMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpAttachmentData}
    }
  }
}
`
export const deletePdpAttachmentMutation = gql`
mutation deletePdpAttachmentMutation($pdpAttachmentUniqueId: String!){
  deletePdpAttachmentMutation(pdpAttachmentUniqueId: $pdpAttachmentUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const getPdpAreaOfApplication = gql`query getPdpAreaOfApplication
($filtering:PdpAreaOfApplicationFilteringInputObject){
  getPdpAreaOfApplication(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${pdpApplicationData}
    }
    }
  }
`
export const createPdpAreaOfApplicationMutation = gql`
mutation createPdpAreaOfApplicationMutation($input: PdpAreaOfApplicationInputObject!){
  createPdpAreaOfApplicationMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpApplicationData}
    }
  }
}
`
export const deletePdpAreaOfApplicationMutation = gql`
mutation DeletePdpAreaOfApplicationMutation($pdpAttachmentUniqueId: String!){
  DeletePdpAreaOfApplicationMutation(pdpAttachmentUniqueId: $pdpAttachmentUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const getSkills = gql`
query getSkills($filtering:SkillFilteringInputObject){
  getSkills(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
    ${SkillsData}
    }
  }
}
`
export const getSkillCategories = gql`
query getSkillCategories($filtering:SkillCategoryFilteringInputObject){
  getSkillCategories(filtering:$filtering){
    ${baseResponseGraphql}
    data{
    ${skillsCategoryData}
    }
  }
}
`



export const getPdpChallenge = gql`query getPdpChallenge
($filtering:PdpChallengeFilteringInputObject){
  getPdpChallenge(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${pdpChallengeData}
    }
    }
  }
`
export const createPdpChallengeMutation = gql`
mutation createPdpChallengeMutation($input: PdpChallengeInputObject!){
  createPdpChallengeMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpChallengeData}
    }
  }
}
`
export const deletePdpChallengeMutation = gql`
mutation deletePdpChallengeMutation($pdpChallengeUniqueId: String!){
  deletePdpChallengeMutation(pdpChallengeUniqueId: $pdpChallengeUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const updatePdpChallengeMutation = gql`
mutation updatePdpChallengeMutation($input: PdpChallengeInputObject!){
  updatePdpChallengeMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpChallengeData}
    }
  }
}`



export const getPdpRemark = gql`query getPdpRemark
($filtering:PdpRemarkFilteringInputObject){
  getPdpRemark(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
      ${pdpRemarkData}
    }
    }
  }
`
export const getPdpRemarkResponse = gql`query getPdpRemarkResponse
($filtering:PdpRemarkResponseFilteringInputObject){
  getPdpRemarkResponse(filtering:$filtering){
    ${baseResponseGraphql}
    ${basePaginationGraphql}
    data{
    data{
    ${pdpRemarkResponseData}
    }
      
    }
    }
  }
`
export const createPdpRemarkMutation = gql`
mutation createPdpRemarkMutation($input: PdpRemarkInputObject!){
  createPdpRemarkMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpRemarkData}
    }
  }
}
`
export const deletePdpRemarkMutation = gql`
mutation deletePdpRemarkMutation($pdpRemarkUniqueId: String!){
  deletePdpRemarkMutation(pdpRemarkUniqueId: $pdpRemarkUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const updatePdpRemarkMutation = gql`
mutation updatePdpRemarkMutation($input: PdpRemarkInputObject!){
  updatePdpRemarkMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpRemarkData}
    }
  }
}`

export const updatePdpStatusMutation = gql`
mutation updatePdpStatusMutation($input: DevelopmentPlanInputObject!){
  updatePdpStatusMutation(input: $input){
    ${baseResponseGraphql}
    data{
      ${pdpData}
    }
  }
}`

export const createPdpRemarkResponseMutation = gql`
mutation createPdpRemarkResponseMutation($input: PdpRemarkResponseInputObject!){
  createPdpRemarkResponseMutation(input: $input){
    ${baseResponseGraphql}
    data{
    ${pdpRemarkData}
    }
    }
}
`

export const deletePdpRemarkResponseMutation = gql`
mutation deletePdpRemarkResponseMutation($pdpRemarkResponseUniqueId: String!){
  deletePdpRemarkResponseMutation(pdpRemarkResponseUniqueId: $pdpRemarkResponseUniqueId){
    ${baseResponseGraphql}
  }
}
`
export const updatePdpRemarkResponseMutation = gql`
mutation updatePdpRemarkResponseMutation($input: PdpRemarkResponseInputObject!){
  updatePdpRemarkResponseMutation(input: $input){
    ${baseResponseGraphql}
    data{
    ${pdpRemarkData}
    }
  }
}`


export const createProjectActivityCommentMutation = gql`
mutation CreateProjectPersonalReportCommentMutation($input: ProjectPersonalReportCommentInputObject!) {
  createProjectPersonalReportCommentMutation(input: $input) {
    response {
      id
      status
      code
      message
    }
    data {
      id
      uniqueId
      createdAt
      projectCommentSendBy {
        profileUniqueId
        userFirstName
        userLastName
      }
      projectComment
      projectCommentResponse {
        id
        uniqueId
        projectCommentRepliedBy {
          profileUniqueId
          userFirstName
          userLastName
        }
        projectCommentResponse
      }
    }
  }
}
`

export const getProjectPersonalReportComment = gql`
query GetProjectPersonalReportComment($filtering: ProjectPersonalReportCommentFilteringInputObject) {
  getProjectPersonalReportComment(filtering: $filtering) {
    response {
      id
      status
      code
      message
    }
    page {
      number
      hasNextPage
      hasPreviousPage
      nextPageNumber
      previousPageNumber
      numberOfPages
      totalItems
      pages
    }
    data {
      id
      uniqueId
      createdAt
      projectCommentSendBy {
        profileUniqueId
        userFirstName
        userLastName
      }
      projectComment
      projectCommentTime
      projectCommentResponse {
        id
        uniqueId
        projectCommentRepliedBy {
          profileUniqueId
          userFirstName
          userLastName
        }
        projectCommentResponse
        projectCommentResponseTime
      }
    }
  }
}`


export const DeleteProjectActivityCommentMutation = gql`
mutation createProjectActivityCommentMutation($input: PdpRemarkResponseInputObject!){
  createProjectActivityCommentMutation(input: $input){
    ${baseResponseGraphql}
    data{
    ${pdpRemarkData}
    }
  }
}`

export const createProjectPersonalReportCommentResponseMutation = gql`
mutation CreateProjectPersonalReportCommentResponseMutation($input: ProjectPersonalReportCommentResponseInputObject!) {
  createProjectPersonalReportCommentResponseMutation(input: $input) {
    response {
      id
      status
      code
      message
    }
    data {
      id
      uniqueId
      createdAt
      projectCommentSendBy {
        profileUniqueId
        userFirstName
        userLastName
      }
      projectComment
      projectCommentResponse {
        id
        uniqueId
        projectCommentRepliedBy {
          profileUniqueId
          userFirstName
          userLastName
        }
        projectCommentResponse
      }
    }
  }
}

`
